
















import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import CostCenterMenu from './Menu.vue'

@Component({
  components: {
    ListView,
    CostCenterMenu,
  },
})
export default class List extends Vue {
  private headers = []
  private topActions = []

  public mounted(): void {
    this.headers = [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Code',
        value: 'erpCode',
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]

    this.topActions = [
      {
        id: 'new',
        route: () => {
          return {
            name: 'Settings/Economy/CostCenters/New',
            params: { id: '0' },
          }
        },
      },
    ]
  }

  private get rowActions() {
    const actions: Array<Object> = [
      {
        id: 'edit',
        route: (item) => {
          return {
            name: 'Settings/Economy/CostCenters/Edit',
            params: { id: item.id },
          }
        },
      },
    ]
    actions.push({ id: 'delete' })
    return actions
  }
}
